<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Transactions
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.studentId"
          placeholder="Student"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'UpdatedTime', order: 'descending' }"
      >
        <el-table-column
          prop="transactionId"
          label="TransactionId"
          :width="this.$widthRatio * 200 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="transactionNo"
          label="Transaction No."
          :width="this.$widthRatio * 120 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="receiptNo"
          label="Receipt No."
          :width="this.$widthRatio * 120 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="studentId"
          label="Student No."
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="studentName"
          label="Student Name"
          :width="this.$widthRatio * 120 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="Product Name"
          :width="this.$widthRatio * 120 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="totalAmount"
          label="Total Amount"
          :width="this.$widthRatio * 120 + 'px'"
        >
          <template #default="scope"> ${{ scope.row.totalAmount }} </template>
        </el-table-column>
        <el-table-column prop="memo" label="Memo">
          <template #default="scope">
            <span v-html="scope.row.memo.replace('|', '<br>')"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="updatedTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Transaction Time"
          :width="this.$widthRatio * 140 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Operation"
          :width="this.$widthRatio * 180 + 'px'"
          align="center"
        >
          <template #default="scope">
            <el-button
              v-if="scope.row.status === 'Pending'"
              type="text"
              class="red"
              @click="handleComplete(scope.$index, scope.row)"
            >
              <el-icon>
                <edit />
              </el-icon>
              Complete
            </el-button>
            <el-button
              v-if="scope.row.status === 'Pending'"
              type="text"
              @click="handleCancel(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Cancel
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/paymate",
      query: {
        studentId: null,
        orderBy: "UpdatedTime",
        orderDirection: "DESC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        gradeList: [],
      },
      tableData: [],
      dialogTitle: "",
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);

        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleCancel(index, row) {
      // twice to confirm
      this.$confirm("Are you sure to cancel this transaction", "Prompt", {
        type: "warning",
      })
        .then(() => {
          postData(`${this.controllerUrl}/cancel`, {
            transactionId: row.transactionId,
            studentId: row.studentId,
            platform: row.platform,
          }).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Cancelled successfully");
              this.loadData();
            } else {
              this.$message.error(
                "Cancelled failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    handleComplete(index, row) {
      // twice to confirm
      this.$prompt("Please input against Receipt No. (optional)", "Tips", {
        confirmButtonText: "Complete",
        cancelButtonText: "Cancel",
      })
        .then((val) => {
          if (val.action === "confirm") {
            postData(`${this.controllerUrl}/complete`, {
              transactionId: row.transactionId,
              studentId: row.studentId,
              receiptNo: val?.value,
              platform: row.platform,
            }).then((res) => {
              if (res.result && res.code === "200") {
                this.$message.success("Cancelled successfully");
                this.loadData();
              } else {
                this.$message.error(
                  "Cancelled failed, error message: " + res.message
                );
              }
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
